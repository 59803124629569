export default {
  header: {
    title: 'Anti-detection browser for multi-accounting',
    upperTitle: 'Work fast & secure',
    text: 'Create thousands of profiles with different IPs and geolocations with no risk of being blocked and 100% checkers passing. Download, register, work.',
    button: 'Get Started',
  },
  browserApp: {
    app: 'Mobile App',
    button: 'Download',
  },
  discount: {
    label: 'It is a Happy New Year gift from the anti-detect browser #1 ',
    title: 'Work without data leaks in affiliate marketing, e-commerce, and bonus hunting in 2025 with discounts on package plans ranging from 30% to 60%',
    button: 'AQUM2025NY',
  },
  solution: {
    upperTitle: 'Cover all areas of the business',
    title: 'A reliable solution for any type of business',
    tiles: {
      1: {
        title: 'Affiliate Marketing',
        text: 'Manage multiple accounts securely, avoid blocking by platforms and optimise advertising strategies while maintaining anonymity',
      },
      2: {
        title: 'Airdrop & Bounty',
        text: 'Use AQUM to create and manage multiple accounts to maximise the number of tokens or rewards you receive',
      },
      3: {
        title: 'E-Commerce',
        text: 'Scale your business on Amazon, eBay and other marketplaces - without regional restrictions or limits on the number of listings, accounts or products',
      },
      4: {
        title: 'Bonus Hunting',
        text: 'Get the most out of bonuses and promotions by managing accounts across multiple platforms without being blocked for suspicious activity and multi-accounting',
      },
      5: {
        title: 'Digital agencies',
        text: 'Use multiple accounts to publish lots of different content to gain reach, as well as run parallel campaigns',
      }
    }
  },
  features: {
    upperTitle: 'Why choose us',
    title: 'Our advantages',
    slider: {
      1: {
        title: 'Drag & Drop Cookies',
        text: 'Download cookies to instantly populate profiles. Adjust manually if necessary'
      },
      2: {
        title: 'Mobile app',
        text: "Manage multi-accounting wherever you are. Share data while you're on the road, on holiday and without access to your main PC",
      },
      3: {
        title: 'Work in Team',
        text: 'Pass profiles and assign responsibilities to team members using a tagging system'
      },
      4: {
        title: 'Canvas',
        text: 'Proprietary fingerprint database based on real data (no spoofing)'
      },
      5: {
        title: 'Extensions',
        text: 'Additional antidetect browser features: the ability to download extensions and use custom solutions'
      },
      6: {
        title: 'Bookmarks',
        text: 'Customise bookmarks, homepage and other digital footprint settings'
      },
    }
  },
  promo: {
    title: 'Special Offer',
    text: 'Test the AQUM anti-detect browser free of charge for 7 days. Take advantage of FREE tariff with the possibility to create up to 5 profiles and add 2 proxies to Favourites',
    button: 'Free Plan',
  },
  provides: {
    title: 'AQUM provides speed and safety',
    text: 'Increase security and efficiency with AQUM. Pass checkers, securely protect your data and quickly handle multiple tasks simultaneously. Start now and get access to exclusive features.',
    button: 'Get Started',
    tiles: {
      1: 'Simultaneous and fast processes',
      2: 'Passing absolutely all checkers',
      3: 'Strong and Advanced Security',
      4: 'Responsive and expert support',
    }
  },
  testimonials: {
    upperTitle: 'Testimonials',
    title: 'What users are saying about us'
  },
  mobileApp: {
    upperTitle: 'Mobile application',
    title: 'AQUM - convenience in a smartphone',
    text: 'The AQUM mobile app is a simplified version of the dashboard, providing 24/7 access to your account and supporting features such as profile transfer and team management. The app is also evolving towards full-profile management capabilities.',
    buttons: {
      google: 'Google Play',
      apple: 'App Store',
    }
  },
  blog: {
    upperTitle: 'AQUM Blog',
    title: 'Blog',
    text: 'We tell you about new features of AQUM anti-deact browser. We share tips and tricks for users of all levels of experience',
    button: 'See More',
  }
}