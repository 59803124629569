<template>
  <section :class="[$style.discount, 'container']">
    <div :class="$style.wrapper">
      <div :class="$style.discountBcg">
        <picture>
          <source srcset="/images/discount/new-year-banner.webp" media="(max-width: 1023px)">
          <img src="/images/discount/new-year-banner.webp" alt=""/>
        </picture>
      </div>
      <div :class="$style.content">
        <div :class="$style.label" v-html="t('home.discount.label')"></div>
        <div :class="$style.title">{{ t('home.discount.title') }}</div>
        <UiButton
          :class="$style.button"
          size="xl"
          :to="localePath('/auth/signup?refId=651ebda8d747dc9bc778a938')"
          color="white"
        >
          <template #label>
            {{ t('home.discount.button') }}
          </template>
        </UiButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style lang="scss" module>
  .discount {
    position: relative;
    padding-bottom: 60px;

    @media (max-width: 1023px) {
      padding-bottom: 30px;
    }

    &Bcg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 24px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .wrapper {
    position: relative;
  }

  .content {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 100px 50px 100px 50px;
    color: #fff;
    text-align: center;

    @media (max-width: 1023px) {
      padding: 33px 72px 50px 72px;
    }

    @media (max-width: 767px) {
      padding: 25px;
    }

    .button {
      @media (min-width: 1023px) {
        min-width: 290px;
        height: 80px;
        border-radius: 50px;
        font-size: 32px;
        line-height: 1;
      }
    }
  }

  .label {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 47px;

    span {
      font-weight: 800;
    }

    @media (max-width: 1023px) {
      font-size: 30px;
      max-width: 500px;
      line-height: 36px;
    }

    @media (max-width: 425px) {
      font-size: 22px;
      max-width: 500px;
      line-height: 24px;
    }

  }

  .title {
    margin: 50px 0 50px 0;
    color: #FFF;
    text-align: center;
    font-size: 37px;
    font-style: normal;
    line-height: 53px;
    max-width: 900px;

    @media (max-width: 1023px) {
      font-size: 27px;
      line-height: 35px;
      margin: 25px 0 50px 0;
    }

    @media (max-width: 425px) {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 28px;
    }

  }
</style>