export default {
  title: 'Download AQUM',
  caption: 'By downloading AQUM, you accept the Terms of Use, User Agreement, Privacy Policy.',
  win: {
    title: 'Windows',
    caption: '<p>- Windows 10 & above</p> <p>- 2GB RAM for 64bit system</p> <p>- No less then 2GB free disk space</p>',
    button: 'Download 64 bit',
  },
  mac: {
    title: 'MacOS',
    caption: '<p>- MacOS Big Sur 11.0 and above</p> <p>- Supporting Intel and Apple Silicon</p>',
    buttonSecondary: 'Download (Intel)',
    button: 'Download (ARM - Apple Silicon)',
  },
  Android: {
    title: 'Android',
    version: '',
    caption: 'Support min Android 8',
    button: 'Download',
  },
  iOS: {
    title: 'iOS',
    version: '',
    caption: 'Support min iOS 16',
    button: 'Download',
  }
};
